<template>
  <div class="banner">
    <img class="img-bg" :src="require('@/assets/image/pic.png')" alt="">
    <div class="flex-col justify-start items-center text-wrapper_2 flash-hover addBtn" @click="onLogo">
      <span class="font_4 text_6">免费发布需求</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeSection1',
  data() {
    return {

    }
  },
  methods: {
    onLogo() {
      if(process.env.NODE_ENV == 'development') {
        window.open('http://lingong.job.cuntoubao.test', '_blank');
      } else if (process.env.NODE_ENV == 'production') {
        window.open('https://lingong.job.cuntoubao.cn/', '_blank');
      }
    }
  }
}
</script>

<style scoped>
.banner {
  position: relative;
  overflow: hidden;
}
.addBtn {
  position: absolute;
  bottom: 20%;
  left: 42%;
  cursor: pointer;
}
.img-bg {
  display: block;
  width: 100%;
  transition: .5s;
  -webkit-transition: .5s;
}
.banner:hover .img-bg {
  transform: scale(1.1);
  transition: .5s;
  -webkit-transition: .5s;
}
</style>
