<template>
  <div>
    <HeadPage />
    
    <div class="main__wrap">
      <Section1></Section1>
      <Section2></Section2>
      <Section3></Section3>
      <Section4></Section4>
      <Section5></Section5>
    </div>

    <FooterPage />
  </div>
</template>

<script>
import 'aos/dist/aos.css';
import AOS from 'aos';
import HeadPage from "@/components/HeadPage";
import FooterPage from "@/components/FooterPage";
import Section1 from './components/section1.vue'
import Section2 from './components/section2.vue'
import Section3 from './components/section3.vue'
import Section4 from './components/section4.vue'
import Section5 from './components/section5.vue'
export default {
  name: "Home",
  components: {
    Section1,
    Section2,
    Section3,
    Section4,
    Section5,
    HeadPage,
    FooterPage,
  },
  data() {
    return {
     
    };
  },
  mounted() {
    this.$nextTick(function () {
      AOS.init({
        easing: 'ease-out-back',
        // duration: 1000
      });
    });
  }
};
</script>

<style>
.page-width {
  width: 1320px;
  margin: 0 auto;
}
.page {
  background-color: #ffffff;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}
.space-x-124 > *:not(:first-child) {
  margin-left: 124px;
}
.image {
  width: 150px;
  height: 40px;
}
.group_2 {
  line-height: 20px;
  height: 20px;
}
.font_1 {
  font-size: 18px;
  font-family: Microsoft YaHei;
  line-height: 20px;
  color: #323232;
}
.text {
  color: #1d7bfc;
  letter-spacing: 2px;
}
.text_2 {
  color: #262626;
  font-size: 18px;
  font-family: Microsoft YaHei;
  line-height: 18px;
  letter-spacing: 2px;
}
.space-x-80 > *:not(:first-child) {
  margin-left: 80px;
}
.space-x-60 > *:not(:first-child) {
  margin-left: 60px;
}
.space-x-11 > *:not(:first-child) {
  margin-left: 11px;
}
.font_2 {
  font-size: 16px;
  font-family: Microsoft YaHei;
  color: #333333;
}
.text_3 {
  line-height: 19px;
}
.image_2 {
  width: 12px;
  height: 7px;
}
.text_4 {
  line-height: 17px;
}
.text-wrapper {
  padding: 12px 0;
  background-color: #1d7bfc;
  border-radius: 6px;
  width: 100px;
  height: 40px;
}
.font_3 {
  font-size: 14px;
  font-family: Microsoft YaHei;
  line-height: 15px;
  color: #0c2f62;
}
.text_5 {
  color: #ffffff;
  line-height: 16px;
}
.section_2 {
  margin-left: 275px;
  background-color: #1d7bfc;
  border-radius: 1px;
  width: 40px;
  height: 4px;
}
.text-wrapper_2 {
  padding: 26px 0;
  background-color: #1d7bfc;
  box-shadow: 0px 4px 16px #abcefff5;
  border-radius: 10px;
  width: 280px;
}
.font_4 {
  font-size: 26px;
  font-family: Microsoft YaHei;
  line-height: 28px;
  color: #ffffff;
}
.text_6 {
  letter-spacing: 3px;
  cursor: pointer;
}
.group_3 {
  /* padding: 81px 300px 0; */
  width: 1320px;
  margin: 0 auto;
  margin-top: 80px;

}
.space-y-23 > *:not(:first-child) {
  margin-top: 23px;
}
.font_5 {
  font-size: 34px;
  font-family: Microsoft YaHei;
  line-height: 36px;
  color: #181818;
}
.text_7 {
  line-height: 35px;
}
.space-x-20 > *:not(:first-child) {
  margin-left: 20px;
}
.equal-division-item {
  flex: 1 1 650px;
  padding: 56px 48px 56px 56px;
  background-color: #f3f8fe;
  border-radius: 10px;
  height: 255px;
}
.space-x-30 > *:not(:first-child) {
  margin-left: 30px;
}
.image_3 {
  width: 145px;
  height: 135px;
}
.group_4 {
  width: 371px;
}
.space-y-22 > *:not(:first-child) {
  margin-top: 22px;
}
.font_66 {
  font-size: 22px;
  font-family: Microsoft YaHei;
  letter-spacing: 2.5px;
  line-height: 24px;
  color: #1d7bfc;
}
.group_5 {
  line-height: 32px;
}
.font_7 {
  font-size: 16px;
  font-family: Microsoft YaHei;
  line-height: 32px;
  color: #0c2f62;
}
.section_4 {
  margin-top: 80px;
  padding: 60px 0;
  background-color: #fafafc;
}
.space-y-60 > *:not(:first-child) {
  margin-top: 60px;
}
.grid {
  height: 914px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 32px;
  column-gap: 23px;
}
.grid-item {
  padding-top: 24px;
  background-color: #ffffff;
  border-radius: 10px;
  cursor: pointer;
}
.image_4 {
  margin-left: 24px;
  width: 50px;
  height: 50px;
}
.text_8 {
  margin-left: 24px;
  margin-top: 24px;
  line-height: 19px;
}
.view_3 {
  margin-top: 15px;
}
.text-wrapper_3 {
  flex: 1 1 112px;
  padding: 12px 0;
  background-color: #f3f8fe;
  border-radius: 4px;
  height: 40px;
  color: #333;
}
.view_6 {
  margin-left: 18px;
}
.view_7 {
  margin-left: 24px;
}
.group_6 {
  padding: 0 24px;
}
.view {
  margin-top: 23px;
}
.space-x-8 > *:not(:first-child) {
  margin-left: 8px;
  margin-top: 2px;
}
.font_8 {
  font-size: 14px;
  font-family: Microsoft YaHei;
  line-height: 15px;
  color: #8c8c8c;
}
.text_9 {
  line-height: 14px;
}
.image_5 {
  width: 7px;
  height: 12px;
}
.view_4 {
  margin-top: 15px;
  max-height: 74px;
  -webkit-transition: .3s;
  transition: .3s;
}
.text_11 {
  line-height: 16px;
}
.view_2 {
  margin-top: 25px;
}
.text_10 {
  line-height: 18px;
}
.space-x-7 > *:not(:first-child) {
  margin-left: 7px;
}
.view_5 {
  margin-top: 15px;
}
.text_13 {
  line-height: 16px;
}
.view_8 {
  margin-top: 24px;
}
.text_14 {
  line-height: 19px;
}
.view_11 {
  margin-top: 15px;
}
.view_9 {
  margin-top: 23px;
}
.text_15 {
  line-height: 19px;
}
.view_12 {
  margin-top: 16px;
}
.text_16 {
  line-height: 14px;
}
.view_10 {
  margin-top: 24px;
}
.view_13 {
  margin-top: 14px;
}
.view_14 {
  margin-top: 24px;
}
.text_17 {
  line-height: 19px;
}
.view_17 {
  margin-top: 15px;
}
.text_19 {
  line-height: 16px;
}
.view_15 {
  margin-top: 23px;
}
.text_18 {
  line-height: 19px;
}
.view_18 {
  margin-top: 16px;
}
.text_20 {
  line-height: 16px;
}
.view_16 {
  margin-top: 24px;
}
.view_19 {
  margin-top: 14px;
}
.text_21 {
  line-height: 16px;
}
.view_20 {
  margin-top: 24px;
}
.view_23 {
  margin-top: 14px;
}
.view_21 {
  margin-top: 23px;
}
.text_22 {
  line-height: 19px;
}
.view_24 {
  margin-top: 16px;
}
.view_22 {
  margin-top: 25px;
}
.text_23 {
  line-height: 19px;
}
.view_25 {
  margin-top: 14px;
}
.text_24 {
  line-height: 14px;
}
.group_1 {
  width: 1320px;
  margin: 0 auto;
  margin-top: 79px;
  padding-bottom: 80px;
  /* padding: 0 300px 80px; */
}
.space-y-87 > *:not(:first-child) {
  margin-top: 87px;
}
.image_6 {
  width: 134px;
  height: 134px;
}
.image_7 {
  width: 95px;
  height: 19px;
}
.group_8 {
  margin: 28px 24px 0 27px;
}
.space-x-139 > *:not(:first-child) {
  margin-left: 139px;
}
.group_9 {
  line-height: 32px;
  text-align: center;
  height: 62px;
}
.font_9 {
  font-size: 18px;
  font-family: Microsoft YaHei;
  line-height: 32px;
  font-weight: 700;
  color: #242830;
}
.font_10 {
  font-size: 18px;
  font-family: Microsoft YaHei;
  line-height: 32px;
  color: #242830;
}
.space-x-118 > *:not(:first-child) {
  margin-left: 118px;
}
.group_10 {
  line-height: 32px;
  text-align: center;
  height: 63px;
}
.group_11 {
  margin-top: 8px;
  padding: 0 211px;
}
.font_11 {
  font-size: 14px;
  font-family: Microsoft YaHei;
  line-height: 15px;
  color: #999999;
}
.text_25 {
  margin-left: 264px;
}
.text_26 {
  line-height: 16px;
}
.section_5 {
  padding: 77px 0 66px;
  background: #376ef8;
  background-image: url('~@/assets/image/lbg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.space-y-38 > *:not(:first-child) {
  margin-top: 38px;
}
.text_27 {
  letter-spacing: 1.5px;
}
.text-wrapper_4 {
  padding: 14px 0;
  border-radius: 4px;
  width: 146px;
  border: solid 1px #ffffff;
}
.text_28 {
  color: #ffffff;
  line-height: 18px;
  cursor: pointer;
}
.section_6 {
  padding: 52px 0 49px;
  background-color: #1a2033;
}
.space-y-6 > *:not(:first-child) {
  margin-top: 6px;
}
.group_13 {
  width: 697px;
}
.space-y-16 > *:not(:first-child) {
  margin-top: 0px;
}
.space-x-38 > *:not(:first-child) {
  margin-left: 38px;
}
.image_8 {
  width: 154px;
  height: 40px;
}
.text_12 {
  font-size: 14px;
  font-family: Microsoft YaHei;
  line-height: 38px;
  color: #878a8e;
}
.group_12 {
  margin-right: 6px;
}
.space-x-44 > *:not(:first-child) {
  margin-left: 44px;
}
.code-box {
  background-color: #fff;
  width: 106px;
  height: 106px;
  display: flex;
  align-items: center;
}
.group_14 {
  padding-left: 191px;
  height: 25px;
  margin-top: 22px;
}
.group_15 {
  margin-top: -14px;
}
.space-x-33-reverse > *:not(:last-child) {
  margin-right: 59px;
}
.text_30 {
  margin: 0 8px;
  line-height: 16px;
}
.font_14 {
  font-size: 14px;
  font-family: Microsoft YaHei;
  line-height: 17px;
  color: #878a8e;
}
</style>