<template>
  <div class="flex-col items-center section_5 space-y-38" data-aos="flip-up">
    <span class="font_4 text_27">专业师傅实名制，放心选；在线交易全程跟进服务，省事更省心</span>
    <div class="flex-col justify-start items-center text-wrapper_4" @click="onLogo">
      <span class="font_7 text_28">免费发布需求</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeSection1',
  data() {
    return {

    }
  },
  methods: {
    onLogo() {
      if(process.env.NODE_ENV == 'development') {
        window.open('http://lingong.job.cuntoubao.test', '_blank');
      } else if (process.env.NODE_ENV == 'production') {
        window.open('https://lingong.job.cuntoubao.cn/', '_blank');
      }
    }
  }
}
</script>

<style scoped>
.text-wrapper_4:hover {
  background: #fff;
  -webkit-transition: .5s;
	transition: .5s;
  cursor: pointer;
}
.text-wrapper_4:hover .text_28 {
  color: #3c73fd;
  -webkit-transition: .5s;
	transition: .5s
}
</style>