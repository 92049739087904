<template>
  <div class="flex-col section_4 space-y-60" data-aos="fade-down">
    <div class="page-width">
      <div class="flex-col items-center space-y-23" style="margin-bottom:60px;">
        <span class="font_5">丰富的服务类目</span>
        <span class="font_1">涵盖建筑、运输、家政服务、环境卫生、园林、餐饮等行业，灵活就业场景更全面</span>
      </div>
      <div class="grid">
        <div v-for="(item) in list.slice(0,12)" :key="item.id" @mouseenter.stop="mouseOver(item)" @mouseleave.stop="mouseLeave(item)">
          <div class="flex-col grid-item section3" data-aos="zoom-in" >
            <div>
              <img
                class="self-start image_4"
                :src="item.image"
              />
              <div class="flex-row justify-between items-center group_6 view">
                <span class="font_1">{{item.name}}</span>
                <div class="flex-row space-x-8" v-if="item.children.length > 3">
                  <span class="font_8 text_9">{{ item.boxshow ? '收起' : '展开' }}</span>
                  <el-image class="shrink-0 image_5" :src="require('@/assets/image/dow1.png')"></el-image>
                </div>
              </div>
            </div>
            <div :class="item.boxshow ? 'view_4 view-up maxheight' : 'view_4 view-up'">
              <div class="flex-row group_7">
                <div v-for="son in item.children" :key="son.id" @click="onLogo">
                  <div :title="son.name" class="flex-col justify-start items-center text-wrapper_3 view_7">
                    {{son.name}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Section3',
  data() {
    return {
      list:[],
      isSpread: false,
      boxshow: false,
    }
  },
  created() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      this.$http.get('/user/flex_index/kindList', {params:{}}).then(res => {
        if(res.code == 1) {
          this.list = res.data.list.map(v => {
            return {
              boxshow: false,
              ...v
            }
          })
        }
      })
    },
    // 跳转发布需求
    handlePage(item,son) {
      this.$router.push({
        path: '/created',
        query:{
          id: item.id,
          sonId: son.id
        }
      })
    },
    togglebox() {
      this.boxshow = !this.boxshow;
      this.isSpread = !this.isSpread;
    },
    mouseOver(item) {
      item.boxshow = !item.boxshow;
    },
    mouseLeave(item) {
      item.boxshow = !item.boxshow;
    },

    onLogo() {
      if(process.env.NODE_ENV == 'development') {
        window.open('http://lingong.job.cuntoubao.test', '_blank');
      } else if (process.env.NODE_ENV == 'production') {
        window.open('https://lingong.job.cuntoubao.cn/', '_blank');
      }
    }
  }
}
</script>

<style scoped>
.section3:hover {
  box-shadow:  0 0 10px #C0DBFF;
  background: #fff;
  /* border: solid 1px #C0DBFF; */
}
.section3 {
	position: relative;
	overflow: hidden
}
.text-wrapper_3 {
  flex: none;
  padding: 0 10px;
  background-color: #f3f8fe;
  border-radius: 4px;
  height: 40px;
  line-height: 40px;
  margin-bottom: 16px;
  border: solid 1px #fff;
  width: 103px;
  overflow: hidden;
}
.group_7 {
  padding: 16px 24px 0 0 !important;
  display: flex;
  flex-wrap: wrap;
}
.grid {
  height: auto;
}
.view_7:hover {
  color: #1D7BFC;
  background: #fff;
  border: solid 1px #1D7BFC;
}
.grid-item:hover .image_5{
  transform: rotate(90deg);
  -webkit-transition: .5s;
	transition: .5s
}
.view-up {
  border-top: solid 1px #e5e5e5;
}
.maxheight {
  max-height: 186px;
  overflow: auto;
  -webkit-transition: .5s;
  transition: .5s;
}
</style>